<div [hidden]="appService.isLoading | async">

  <div class="row" style="margin-bottom:15px;">

    <p>If you'd like to update data via XML only in the registry, please contact us at <a href="mailto:Registrysupport@elso.org" data-placement="auto">RegistrySupport&#64;elso.org</a> and we can set up your center for XML Import.</p>
    <br />

    <div style="text-align: center;" *ngIf="showUploadButton == false">
      <h1>Data successfully updated/inserted!</h1>
      <br /><br />
          <p>
            Start XML import again
            <br /><br />
            <button class="form-execute-buttons" (click)="resetXMLValidationObject()" type="button" color="primary" mat-raised-button>Start Import</button>
          </p>
      <br /><br />
    </div>

    <div class="col-md-3">
      <input type="file" #file placeholder="Choose file" (change)="uploadFile()" style="display:none;" name="uploadXMLFile">
      <button class="form-execute-buttons" (click)="file.click()" type="button" color="primary" mat-raised-button>Upload and validate data</button>
      <br />
      <br />
      <mat-checkbox name="sendXMLValidationToEmail" [(ngModel)]="sendXMLValidationToEmail">Send me an email for import validation status: </mat-checkbox>
    </div>

    <br />
    <div>

      <!--Patient/runs that will be inserted/updated-->
      <div *ngIf="xmlValidationObject.PatientsToUpdateOrAdd.length > 0">

        <div *ngFor="let patient of xmlValidationObject.PatientsToUpdateOrAdd">
          <div>Patient uniqueid: {{patient.Patient.UniqueId}} <span *ngIf="patient.PatientWillBeUpdated == true" class="red">-->This patient is new.</span><span *ngIf="patient.PatientWillBeUpdated == true" class="red">-->This patient was previously imported and will be updated.</span></div>
          <div *ngFor="let runErrorList of xmlValidationObject.PatientRunErrors">
            <ng-container *ngIf="runErrorList.UniqueId == patient.Patient.UniqueId">
              <div style="padding-left: 10px;">RunNo: {{runErrorList.RunNo}}</div>
              <div *ngFor="let error of runErrorList.ListOfErrors" style="padding-left: 20px;" [ngClass]="{'hard-error': error.TypeOfValidation == 1, 'soft-error' : error.TypeOfValidation == 2, 'required-field' : error.TypeOfValidation == 3, 'xml-allowed-error' : error.XMLRunCompletionRequired == 1 }">
                {{error.ParentTabName}} > {{error.Key}} - {{error.ErrorMessage}}
              </div>
            </ng-container>
          </div>
        </div>


        <div [hidden]="showUploadButton != true">
          <mat-checkbox name="sendXMLConfirmationToEmail" [(ngModel)]="sendXMLConfirmationToEmail">Send me an email for import result status: </mat-checkbox>
          <br />
          The patients and runs above will be added/updated when you press the Confirm button:
          <button class="form-execute-buttons" (click)="updateAddPatientsAndRuns()" type="button" color="primary" mat-raised-button>Confirm</button>
        </div>
      </div>


      <!--Format errors present in XML import file-->
      <div *ngIf="xmlValidationObject.XMLNotFormatedErrors.length > 0">
        <div *ngFor="let formatError of xmlValidationObject.XMLNotFormatedErrors" style="color: red;">
          {{formatError.ErrorMessage}}
        </div>
      </div>
      <!--Format errors present in XML import file-->
      <div *ngIf="xmlValidationObject.XMLFormatErrorsList.length > 0">
        <div *ngFor="let formatError of xmlValidationObject.XMLFormatErrorsList" style="color: red;">
          {{formatError}}
        </div>
      </div>


      <!--Missing required fields and invalid values inside fields-->
      <div *ngIf="xmlValidationObject.ListOfNotEligiblePatients.length > 0">
        <div>
          These patients and runs cannot be added/updated because of these errors:
        </div>
        <div *ngIf="xmlValidationObject.XMLRequiredFieldsAndWrongValuesErrorsList.length > 0">
          <div>Patients and runs that didnt pass conversion from XML:</div>
          <div *ngFor="let patient of xmlValidationObject.ListOfNotEligiblePatients">
            <ng-container *ngIf="patient.CenterNoNotValid == true">
              <div style="padding-left: 20px; color: red;">
                {{patient.UniqueId}} > User is not allowed to import patient data for sent center no.
              </div>
            </ng-container>
            <ng-container *ngIf="patient.ConversionErrorsPresent == true">
              <div>Patient uniqueid: {{patient.UniqueId}} - <span *ngIf="patient.NotXMLImported != true">{{patient.PatientExists == true ? 'This patient was previously imported and will be updated' : 'This patient is new'}}</span> <span *ngIf="patient.NotXMLImported == true" class="red">This patient was previously manually entered and cannot be updated with the XML Import!</span></div>
              <div *ngFor="let runErrorList of xmlValidationObject.PatientRunErrors">
                <ng-container *ngIf="runErrorList.UniqueId == patient.UniqueId">
                  <div *ngFor="let error of runErrorList.ListOfErrors" style="padding-left: 20px; color: red;">
                    <ng-container *ngIf="error.SectionName == 'Patient'">
                      {{error.SectionName}} > {{error.FieldName}} - {{error.ErrorMessage}}
                    </ng-container>
                  </div>
                  <div style="padding-left: 10px;">RunNo: {{runErrorList.RunNo}}</div>
                  <div *ngFor="let error of runErrorList.ListOfErrors" style="padding-left: 20px; color: red;">
                    <ng-container *ngIf="error.SectionName != 'Patient'">
                      {{error.SectionName}} > {{error.FieldName}} - {{error.ErrorMessage}}
                    </ng-container>
                  </div>
                </ng-container>
              </div>

              <!--need to somehow show RunNo here, but not in all cases when RuNno is not valid like 'asd'. SHould probably group those errors by run no-->
              <!--first we should list all errors that have RunNo == 0 from XMLRequiredFieldsAndWrongValuesErrorsList, that means those errors are for patient only -->
              <!--<div *ngFor="let error of xmlValidationObject.XMLRequiredFieldsAndWrongValuesErrorsList" class="hard-error">
      <ng-container *ngIf="error.UniqueId == patient.UniqueId" style="padding-left: 20px;">
        {{error.SectionName}} > {{error.FieldName}} - {{error.ErrorMessage}}
      </ng-container>
    </div>-->
            </ng-container>
          </div>
        </div>

        <div *ngIf="xmlValidationObject.PatientRunErrors.length > 0">
          <div *ngFor="let patient of xmlValidationObject.ListOfNotEligiblePatients">
            <ng-container *ngIf="patient.ConversionErrorsPresent != true">
              <div>Patient uniqueid: {{patient.UniqueId}} - <span *ngIf="patient.NotXMLImported != true">{{patient.PatientExists == true ? 'This patient was previously imported and will be updated' : 'This patient is new'}}</span><span *ngIf="patient.NotXMLImported == true" class="red">This patient was previously manually entered and cannot be updated with the XML Import!</span></div>
              <div *ngFor="let runErrorList of xmlValidationObject.PatientRunErrors">
                <ng-container *ngIf="runErrorList.UniqueId == patient.UniqueId">
                  <div style="padding-left: 10px;">RunNo: {{runErrorList.RunNo}}</div>
                  <div *ngFor="let error of runErrorList.ListOfErrors" style="padding-left: 20px;" [ngClass]="{'hard-error': error.TypeOfValidation == 1, 'soft-error' : error.TypeOfValidation == 2, 'required-field' : error.TypeOfValidation == 3, 'xml-allowed-error' : error.XMLRunCompletionRequired == 1 }">
                    {{error.ParentTabName}} > {{error.Key}} - {{error.ErrorMessage}}
                  </div>
                </ng-container>
              </div>
            </ng-container>
          </div>
        </div>
      </div>



    </div>










    <!--<div class="col-md-4">
    <span class="upload" *ngIf="progress > 0">
      {{progress}}%
    </span>
    <span class="upload" *ngIf="message">
      {{message}}
    </span>
  </div>-->
  </div>




</div>
