<div [hidden]="appService.isLoading | async">
  <span>Center specific reports are now available <a href="https://www.elso.org/registry/internationalsummaryandreports/centerspecificreports.aspx" target="_blank">here</a></span>
  <br />
  <span>Center Statistics:</span>
  <br />

  <mat-form-field class="select-form-field">
    <mat-select hideSingleSelectionIndicator name="countryString" [(ngModel)]="chartType" (selectionChange)="graphOptionSelect($event.value)">
      <mat-option *ngFor="let graph of enumService.enumGraphs" [value]="graph.value">
        {{graph.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <div id="container"></div>
  <mat-divider></mat-divider>
  <div *ngIf="chartType == 1 || chartType == 2">
    <img style="width: 12em; display: block; margin: 0.5em auto auto;" src="../../../assets//images//graph-legend.png">
  </div>
  <br />
  <br />
  <div class="row" style="text-align: right" *ngIf="appService.UserProfile.CanEditRunPatient">
    <button *ngIf="!appService.CenterIsXML" [routerLink]="['/patient/addpatient/0']" class="form-execute-buttons" type="button" color="primary" mat-raised-button matTooltip="Add patient" matTooltipPosition="above">Quick Add</button>
    <button [routerLink]="['/patient/addpatient/1']" class="form-execute-buttons" type="button" color="primary" mat-raised-button matTooltip="Add test patient" matTooltipPosition="above">Quick Add Test</button>
  </div>
  <br />
  <label>Search: &nbsp;</label>
  <input type="text" name="query" [(ngModel)]="query" placeholder="Search by uniqueid" />
  <br />
  <br />
  <div>Incomplete Runs</div>
  <div class="elsoGridFilters">
    <div style="display:inline-block">
      <mat-form-field class="select-form-field" floatLabel="always" appearance="fill">
        <mat-select hideSingleSelectionIndicator name="elsoGridSortByProperty" id="elsoGridSortByProperty" placeholder="-Sort by-" [(ngModel)]="elsoGridSortBy">
          <mat-option>-Sort by-</mat-option>
          <mat-option *ngFor="let sortObj of
                    [
                      { PropertyName: 'UniqueId', PropertyDisplayName: 'UniqueId'},
                      { PropertyName: 'RunNo', PropertyDisplayName: 'Run no'},
                      { PropertyName: 'TimeOn', PropertyDisplayName: 'Time on'},
                      { PropertyName: 'Birthdate', PropertyDisplayName: 'Birthdate'},
                    ]"
                      [value]="sortObj.PropertyName">
            {{sortObj.PropertyDisplayName}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    &nbsp;&nbsp;&nbsp;
    <div style="display:inline-block">
      <mat-form-field class="select-form-field" floatLabel="always" appearance="fill">
        <mat-select hideSingleSelectionIndicator name="elsoGridOrderByProperty" id="elsoGridOrderByProperty" placeholder="-Asc/Desc-" [(ngModel)]="elsoGridOrderBy">
          <mat-option>-Asc/Desc-</mat-option>
          <mat-option *ngFor="let orderByObj of ['asc', 'desc']" [value]="orderByObj">
            {{orderByObj}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="elso-grid">
    <div class="elso-grid-row" *ngFor="let run of (incompleteRuns | elsoGridFilter:query:elsoGridSortBy:elsoGridOrderBy |
                         slice: paginatorService.lowValue : paginatorService.highValue)">
      <div>
        <a (click)="loadRun(run.RunId)">
          <mat-icon class="mat-icon" matTooltip="Run" matTooltipPosition="above" color="primary">assignment</mat-icon>
        </a>
        <a (click)="loadPatient(run.PatientId)">
          <mat-icon class="mat-icon" matTooltip="Patient" matTooltipPosition="above" color="primary">assignment_ind</mat-icon>
        </a>
        <ng-container *ngIf="run.TestRun">
          <span><mat-icon class="mat-icon" matTooltip="Delete run" (click)="deleteRun(run)" matTooltipPosition="above" color="primary">delete</mat-icon></span>
          <span><mat-icon class="mat-icon" matTooltip="Delete patient" (click)="deletePatient(run.PatientId)" matTooltipPosition="above" color="primary">delete_forever</mat-icon></span>
          <span style="color: white; background-color: #3F51B5; border-radius: 5px; padding: 5px; position: relative; top: -7px; font-size: 12px;">Test Patient</span>
        </ng-container>
      </div>
      <div>
        <span class="elso-grid-label">Unique id: </span>
        <span>{{run.UniqueId}}</span>
      </div>
      <div>
        <span class="elso-grid-label">Run no: </span>
        <span>{{run.RunNo}}</span>
      </div>
      <div>
        <span class="elso-grid-label">Birthdate: </span>
        <span>{{run.Birthdate | dateFormat}}</span>
      </div>
      <div>
        <span class="elso-grid-label">Age: </span>
        <span>{{appService.getFormatedStringFromDays(run.Age)}}</span>
      </div>
      <div>
        <span class="elso-grid-label">Time on: </span>
        <span>{{run.TimeOn | dateFormat}}</span>
      </div>
      <div>
        <span class="elso-grid-label">Time off: </span>
        <span>{{run.TimeOff | dateFormat}}</span>
      </div>
    </div>
  </div>

  <br />
  <br />
  <mat-paginator *ngIf="incompleteRuns" [length]="incompleteRuns.length" pageSize=5
                 (page)="paginatorService.getPaginatorData($event)" [pageSizeOptions]="[5, 20, 30]"
                 showFirstLastButtons="false">
  </mat-paginator>
</div>
