import { Component, Inject, ViewChild } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MatSidenav } from '@angular/material/sidenav';
//import { delay } from 'rxjs/operators';
import { ActivationEnd, ChildActivationEnd, NavigationEnd, RouteConfigLoadEnd, RouteConfigLoadStart, Router } from '@angular/router';
import { LoadingService } from './services/loading.service';
import { AppService } from './services/app.service';
import { BehaviorSubject } from 'rxjs';
import { DataService } from './services/data.service';
import { ToastrService } from 'ngx-toastr';
import { BroadcastService } from './services/broadcast.service';
//new menu
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DateTimeFormatService } from './services/datetime-format.service';
import moment from 'moment';
import { EnumService } from './services/enum.service';
/////

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
  // changeDetection: ChangeDetectionStrategy.OnPush //for LoadingService --But redirect from login to home page wont work properly if uncomented
})
export class AppComponent {
  @ViewChild('sidenav1')
  sidenav1!: MatSidenav;

  @ViewChild('sidenav2')
  sidenav2!: MatSidenav;

  currentYear;
  activeLink = "";
  constructor(private observer: BreakpointObserver, public router: Router, public loadingService: LoadingService, public appService: AppService, public enumService: EnumService
    , private dataService: DataService, private toastr: ToastrService, private broadcastService: BroadcastService, private fb: FormBuilder
    , private _dateTimeService: DateTimeFormatService) {
	this.currentYear = new Date().getFullYear();
  }

//new menu
  form!: FormGroup;
  showCentersList: boolean = false;
  centers = [];
  filteredCenters = [];
  centerQuery: string = '';
////
  ngOnInit() {

    //Added on 11-05-2024, in some case user token is set but user profile and local storage for some properties is not set. Probably because of Auth0
    //Or maybe this issue was because of testing with live elso DNN and betaregistry sites
    if (this.appService.UserProfile.UserName == '' && localStorage.getItem('token') != undefined) {
      this.logout();
    }

    //This is used to show loading spinner when lazy load module is being loaded
    this.router.events.subscribe(event => {
      //console.log(this.router);
      if (event instanceof ActivationEnd) {

        if (event.snapshot.routeConfig.path) {
          // console.log(event.snapshot.routeConfig.path);

          if (event.snapshot.routeConfig.path != 'run' && event.snapshot.routeConfig.path != 'patient' && event.snapshot.routeConfig.path.trim() != '') {
            this.activeLink = event.snapshot.routeConfig.path;
          }
        }
      }
      if (event instanceof RouteConfigLoadStart) {
        this.appService.isLoading = new BehaviorSubject(true);
      } else if (event instanceof RouteConfigLoadEnd) {
        this.appService.isLoading = new BehaviorSubject(false);
      }
	  //Will scroll content holder to top on route change -- added on 03-04-2024
      this.router.events.subscribe((evt) => {
        if (!(evt instanceof NavigationEnd)) {
          return;
        }
        var el = document.getElementById('sideNavMain');
        if (el)
          el.scrollTo(0, 0)
      });
      /////////////////////////
    });
	//new menu
    this.form = this.fb.group({
      UnitSystem: new FormControl(parseInt(this.appService.UserProfile.UnitSystem)),
      CultureOptionSelected: new FormControl(parseInt(this.appService.UserProfile.CultureOptionSelected)),
      CenterSelected: new FormControl(this.appService.UserProfile.CenterId)
    });

    if (localStorage.getItem('token') != undefined && localStorage.getItem('token') != null) {
      this.dataService.setUserCenter();
    }

    //refresh roles for user
    if (this.appService.UserProfile.UserRoles.length > 0) {
      //console.log(this.appService.UserProfile);
      this.dataService.getall('Users/RefreshRolesForUser').subscribe(
      (res: any) => {
        localStorage.setItem('token', res.data);

        this.appService.UserProfile.UserRoles = this.appService.getUserRoles();
        this.appService.UserProfile.CanEditRunPatient = this.appService.roleMatch(this.appService.editPatientRunRoles);

        if (this.appService.isUserInRole('Host User') == true || this.appService.isUserInRole('Host User - Registry Only') == true
          || this.appService.isUserInRole('Multi Center User') == true || this.appService.isUserInRole('Multi Center XML Import') == true) {
          //get centers for user, show dd list
          this.dataService.getall('Users/GetCentersForUser').subscribe(
            (res: any) => {
              //this.centers = res.data;
              //this.showCentersList = true;
              this.appService.userCenters = res.data;

              this.appService.centerQuery = this.appService.UserProfile.CenterName; //this.appService.lodashFindBy(this.appService.userCenters, 'CenterId', this.appService.UserProfile.CenterId).CenterId;

              this.appService.filteredCenters = this.appService.userCenters;

            },
            err => {
              console.log(err);
            }
          );
        }
      },
      err => {
        console.log(err);
      });
      ///
    }
  }

  onSelectionChangeCenter(event) {
    //this.save();

    if (typeof this.appService.centerQuery == 'object') {
      var object: any = this.appService.centerQuery;

      this.appService.UserProfile.CenterId = object.CenterId;

      this.save();
    }
  }

  filterCenters(event) {
    var filterValue;
    
    if (typeof this.appService.centerQuery == 'object') {
      var object: any = this.appService.centerQuery;
      filterValue = object.CenterId;
      this.appService.filteredCenters = this.appService.userCenters.filter(option => option.CenterId == filterValue);
    } else if (typeof this.appService.centerQuery == 'string') {
      
      if (!isNaN(Number(this.appService.centerQuery))) {
        this.appService.filteredCenters = this.appService.userCenters.filter(option => String(option.CenterNo).toLowerCase().includes(this.appService.centerQuery));
      } else {
        this.appService.filteredCenters = this.appService.userCenters.filter(option => option.Name.toLowerCase().includes(this.appService.centerQuery));
      }
    }
  }

 private displayFnCenter = value => {
    // now you have access to 'this'
    if (value != null) {
      if (typeof value == 'object') {
        return value.Name;
      } else {
        return this.appService.UserProfile.CenterName;
        //this.appService.lodashFindBy(this.appService.userCenters, 'CenterId', value).CenterNo ;
        //return this.appService.lodashFindBy(this.appService.userCenters, 'CenterId', value).Name;
      }
    }
  }

  onSelectionChange() {
    this.save();
  }

  save() {
    var userProfile = {
      CenterSelected: this.appService.UserProfile.CenterId //this.form.controls['CenterSelected'].value
    }

    this.dataService.update('Users/UpdateUserCenter', userProfile).subscribe(
      (res: any) => {
        if (res.data) {//update user center if center was changed
          localStorage.setItem('token', res.data.token);
          localStorage.setItem('centerName', res.data.Name);
          localStorage.setItem('centerId', res.data.CenterId);
        }
        
        this.appService.setUserProfile();

        this.dataService.setDeviceEnums();//Update devices lists when center is changed //07-31-2024

        this.appService.Center = res.data.center;

        if (this.appService.Center.GoLiveAfterDate != null) {
          this.appService.CenterIsXML = true;
        } else {
          this.appService.CenterIsXML = false;
        }

        //console.log(this.appService.UserProfile.UserRoles);
        //console.log(this.appService.userCenters);
        if (location.pathname == '/home') {
          location.reload();
        } else {
          this.router.navigateByUrl('/home')
        }
      },
      err => {
        console.log(err);
      }
    )
  }
  //checkIfSectionActive(sectionName: string) {
  //  var pattern = new RegExp("(<=\\s|\\b)" + sectionName + "(?=[]\\b|\\s|$)");
  //  console.log(pattern.test(this.activeLink));
  //}

  changeRunRoute(section: string) {
    //console.log(this.sidenav1.opened);
    this.sidenav1.opened = false;//this will close main sidenav
    this.sidenav2.opened = false;//this will close run section sidenav

    var routeUrl = '';

    if (this.appService.InRunEditMode == 'view') {
      routeUrl = '/run/' + section + '/' + this.appService.Run.RunId;
    }
    else if (this.appService.InRunEditMode == 'edit') {
      if (this.appService.Run.Source != 7) {//if run not XML import, change route to edit mode
        routeUrl = '/run/' + section + '/edit/' + this.appService.Run.RunId;
      } 
    }

    if (!routeUrl.includes('/edit')) {//view mode
      //console.log(routeUrl);
      this.router.navigateByUrl(routeUrl);
    } else {//edit mode

      if (this.appService.lodashFindBy(this.enumService.enumForceViewModeSections, 'name', section)) {
        routeUrl = routeUrl.replace('edit/', '');
      }

      if (this.appService.Run.submittedDate != null) {
        //Run is submitted
        var navigate = this.unsubmitRun();

        if (navigate) {
          this.router.navigateByUrl(routeUrl);
        }
      } else {

        this.broadcastService.sendMessage(routeUrl);

        //this.router.navigateByUrl(routeUrl);
      }
    }
  }

  changeAddendaRoute(section: string) {
    this.sidenav1.opened = false;//this will close main sidenav
    this.sidenav2.opened = false;//this will close run section sidenav

    var routeUrl = '';

    if (this.appService.InRunEditMode == 'view') {
      routeUrl = '/run/addenda/' + section + '/' + this.appService.Run.RunId;
    }
    else if (this.appService.InRunEditMode == 'edit') {
      if (this.appService.Run.Source != 7) {//if run not XML import, change route to edit mode
        routeUrl = '/run/addenda/' + section + '/edit/' + this.appService.Run.RunId;
      }
    }

    if (!routeUrl.includes('/edit')) {//view mode
      //console.log(routeUrl);
      this.router.navigateByUrl(routeUrl);
    } else {//edit mode

      if (this.appService.lodashFindBy(this.enumService.enumForceViewModeSections, 'name', section)) {
        routeUrl = routeUrl.replace('edit/', '');
      }

      if (this.appService.Run.submittedDate != null) {
        //Run is submitted
        var navigate = this.unsubmitRun();

        if (navigate) {
          this.router.navigateByUrl(routeUrl);
        }
      } else {

        this.broadcastService.sendMessage(routeUrl);

        //this.router.navigateByUrl(routeUrl);
      }
    }
  }

  unsubmitRun = function (): boolean {
    var result = confirm("If you edit this run, it will become incomplete and will need to be submitted again.");
    if (!result) {
      //cancel navigation
      return false;
    } else {
      //unsubmit run
      this.dataService.update('Runs/UnsubmitRun', this.appService.Run).subscribe(
        (res: any) => {
          this.appService.Run = res.data.Run;

          return true;
        },
        err => {
          console.log(err);
        }
      );
    }
  };

  ngAfterViewInit() {
    //this.observer
    //  .observe(['(max-width: 800px)'])
    //  .pipe(delay(1))
    //  .subscribe((res) => {
    //    if (res.matches) {
    //      this.sidenav.mode = 'over';
    //      this.sidenav.close();
    //    } else {
    //      this.sidenav.mode = 'side';
    //      this.sidenav.open();
    //    }
    //  });
  }

  closeAllSideNavs() {
    this.sidenav1.opened = false;
    this.sidenav2.opened = false;
  }

  changeMainRoute(route: string) {
    this.closeAllSideNavs();
    this.router.navigateByUrl(route);
  }

 _base64ToArrayBuffer(base64) {
    const binary_string = window.atob(base64);
    const len = binary_string.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  patientAllReport() {
    this.dataService.getFile('PatientListReport/GenerateReport', null, null).subscribe(
      (res: File) => {
        console.log(res);
        var formatedDate = moment().utc(true).locale(this._dateTimeService.locale).format(this._dateTimeService.format);
        var a = document.createElement("a");
        a.href = URL.createObjectURL(res);
        a.download = 'File-' + formatedDate + '.pdf';
        // start download
        a.click();
      },
      err => {
        console.log(err);
      }
    );
  }
  dataDumpMembraneLungs() {
    this.closeAllSideNavs();
    this.dataService.getFile('DataDump/DataDumpMembraneLungsList', null, null).subscribe(
      (res: File) => {
        var date = new Date();
        var a = document.createElement("a");
        a.href = URL.createObjectURL(res);
        a.download = 'MembraneLungsFile-' + String(date.getFullYear()) + '-' + String(date.getMonth() + 1) + '-' + String(date.getDate()) + '.xlsx';
        // start download
        a.click();
      },
      err => {
        console.log(err);
      }
    );
  }

  dataDumpBloodPumps() {
    this.closeAllSideNavs();
    this.dataService.getFile('DataDump/DataDumpPumpsList', null, null).subscribe(
      (res: File) => {
        var date = new Date();
        var a = document.createElement("a");
        a.href = URL.createObjectURL(res);
        a.download = 'PumpsFile-' + String(date.getFullYear()) + '-' + String(date.getMonth() + 1) + '-' + String(date.getDate()) + '.xlsx';
        // start download
        a.click();
      },
      err => {
        console.log(err);
      }
    );
  }

  dataDumpHemofilters() {
    this.closeAllSideNavs();
    this.dataService.getFile('DataDump/DataDumpHemofiltersList', null, null).subscribe(
      (res: File) => {
        var date = new Date();
        var a = document.createElement("a");
        a.href = URL.createObjectURL(res);
        a.download = 'HemofiltersFile-' + String(date.getFullYear()) + '-' + String(date.getMonth() + 1) + '-' + String(date.getDate()) + '.xlsx';
        // start download
        a.click();
      },
      err => {
        console.log(err);
      }
    );
  }

  dataDumpHeatExchangers() {
    this.closeAllSideNavs();
    this.dataService.getFile('DataDump/DataDumpHeatExchangersList', null, null).subscribe(
      (res: File) => {
        var date = new Date();
        var a = document.createElement("a");
        a.href = URL.createObjectURL(res);
        a.download = 'HeatExchangersFile-' + String(date.getFullYear()) + '-' + String(date.getMonth() + 1) + '-' + String(date.getDate()) + '.xlsx';
        // start download
        a.click();
      },
      err => {
        console.log(err);
      }
    );
  }

  dataDumpTemperatureRegulations() {
    this.closeAllSideNavs();
    this.dataService.getFile('DataDump/DataDumpTemperatureRegulationsList', null, null).subscribe(
      (res: File) => {
        var date = new Date();
        var a = document.createElement("a");
        a.href = URL.createObjectURL(res);
        a.download = 'TemperatureRegulationsFile-' + String(date.getFullYear()) + '-' + String(date.getMonth() + 1) + '-' + String(date.getDate()) + '.xlsx';
        // start download
        a.click();
      },
      err => {
        console.log(err);
      }
    );
  }

  dataDumpCannulas() {
    this.closeAllSideNavs();
    this.dataService.getFile('DataDump/DataDumpCannulasList', null, null).subscribe(
      (res: File) => {
        var date = new Date();
        var a = document.createElement("a");
        a.href = URL.createObjectURL(res);
        a.download = 'CannulasFile-' + String(date.getFullYear()) + '-' + String(date.getMonth() + 1) + '-' + String(date.getDate()) + '.xlsx';
        // start download
        a.click();
      },
      err => {
        console.log(err);
      }
    );
  }

  dataDumpManufacturers() {
    this.closeAllSideNavs();
    this.dataService.getFile('DataDump/DataDumpManufacturersList', null, null).subscribe(
      (res: File) => {
        var date = new Date();
        var a = document.createElement("a");
        a.href = URL.createObjectURL(res);
        a.download = 'ManufacturersFile-' + String(date.getFullYear()) + '-' + String(date.getMonth() + 1) + '-' + String(date.getDate()) + '.xlsx';
        // start download
        a.click();
      },
      err => {
        console.log(err);
      }
    );
  }

  dataDumpEquipmentMaster() {
    this.closeAllSideNavs();
    this.dataService.getFile('DataDump/DataDumpEquipmentMasterList', null, null).subscribe(
      (res: File) => {
        var date = new Date();
        var a = document.createElement("a");
        a.href = URL.createObjectURL(res);
        a.download = 'EquipmentMasterFile-' + String(date.getFullYear()) + '-' + String(date.getMonth() + 1) + '-' + String(date.getDate()) + '.xlsx';
        // start download
        a.click();
      },
      err => {
        console.log(err);
      }
    );
  }

  logout() {    
    this.closeAllSideNavs();
    this.dataService.logout().subscribe(
      (res: any) => {
        localStorage.removeItem('cultureSettings');
        localStorage.removeItem('unitSystem');
        localStorage.removeItem('cultureOptionSelected');
        localStorage.removeItem('centerName');
        localStorage.removeItem('centerId');
        localStorage.removeItem('userName');
        localStorage.removeItem('token');
        localStorage.removeItem('auth0token');
        localStorage.removeItem('datePickerType');
        localStorage.removeItem('goLiveAfterDate');
        localStorage.removeItem('TestXMLLogin');

		    this.appService.RunEnumsList = null;
        this.appService.userCenters = [];
        this.appService.UserProfile = null;
        this.toastr.success('Welcome to ELSO Registry', 'Logout success.');
        //this.router.navigateByUrl('/login');
        console.log(location.hostname);
        if (location.hostname != 'localhost') {
          window.location.href = 'https://authentication.elso.org/logout';
        } else {
          this.router.navigateByUrl('/sloginE0E52F31B39847C3A8C');
        }
      },
      err => {
        console.log(err);
      }
    );
  }

  getFormatedStringFromDays(numberOfDays) {
    if (numberOfDays == 0) {
      return "0 days";
    }

    var years = Math.floor(numberOfDays / 365);
    var months = Math.floor(numberOfDays % 365 / 30);
    var days = Math.floor(numberOfDays % 365 % 30);

    var yearsDisplay = years > 0 ? years + (years == 1 ? " year" : " years") : "";
    var monthsDisplay = months > 0 ? months + (months == 1 ? " month" : " months") : "";
    var daysDisplay = days > 0 ? days + (days == 1 ? " day" : " days") : "";

    if (years >= 1) {
      return yearsDisplay;
    } else if (months >= 1) {
      return monthsDisplay;
    } else if (days >= 1) {
      return daysDisplay;
    }
    //return yearsDisplay + monthsDisplay + daysDisplay;
  }
}
