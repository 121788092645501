export class DeviceEnums {
  constructor() { }
  enumAllMembraneLungs = []
  enumMembraneLungs = []
  enumMembraneLungsManufacturers = []
  enumAllBloodPumps = []
  enumBloodPumps = []
  enumBloodPumpsManufacturers = []
  enumAllHemofilters = []
  enumHemofilters = []
  enumHemofiltersManufacturers = []
  enumAllTemperatureRegulations = []
  enumTemperatureRegulations = []
  enumTemperatureRegulationsManufacturers = []
  enumAllCannulas = []
  enumCannulas = []
  enumCannulasManufacturers = []
  enumAllConsoles = []
  enumConsoles = []
  enumConsolesManufacturers = []
  membraneLungsCenterSpecific = []
  bloodPumpsCenterSpecific = []
  hemofiltersCenterSpecific = []
  temperatureRegulationsCenterSpecific = []
  cannulasCenterSpecific = []
  consolesCenterSpecific = []
}
