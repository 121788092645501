<div [hidden]="appService.isLoading | async">
  <form [formGroup]="form">
    <fieldset>
      <legend>
        User profile
      </legend>

      <div class="control-item">
        <label for="CultureOptionSelected">Date/time format:</label>
        <br />
        <mat-form-field class="select-form-field" floatLabel="always" appearance="fill">
          <mat-select hideSingleSelectionIndicator name="CultureOptionSelected" id="CultureOptionSelected" placeholder="Please select" formControlName="CultureOptionSelected">
            <mat-option *ngFor="let culture of enumService.enumFormatOptions" [value]="culture.value">
              {{culture.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div class="hard-error" *ngIf="form.controls['CultureOptionSelected'].hasError('required')">This field is required!</div>
      </div>

      <div class="control-item">
        <label for="UnitSystem">Unit system:</label>
        <br />
        <mat-form-field class="select-form-field" floatLabel="always" appearance="fill">
          <mat-select hideSingleSelectionIndicator name="UnitSystem" id="UnitSystem" placeholder="Please select" formControlName="UnitSystem">
            <mat-option *ngFor="let unit of enumService.enumUnitSystem" [value]="unit.value">
              {{unit.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div class="hard-error" *ngIf="form.controls['UnitSystem'].hasError('required')">This field is required!</div>
      </div>

      <div class="control-item">
        <label for="UnitSystem">Date Pickery:</label>
        <br />
        <mat-form-field class="select-form-field" floatLabel="always" appearance="fill">
          <mat-select hideSingleSelectionIndicator name="DatePickerType" placeholder="Please select" formControlName="DatePickerType">
            <mat-option>Please select</mat-option>
            <mat-option *ngFor="let picker of enumService.enumDatePickerType" [value]="picker.value">
              {{picker.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div class="hard-error" *ngIf="form.controls['DatePickerType'].hasError('required')">This field is required!</div>
      </div>

      <!--<div class="control-item" *ngIf="showCentersList">
    <mat-form-field class="select-form-field">
      <mat-label>Center:</mat-label>
      <mat-select hideSingleSelectionIndicator name="Center" placeholder="Please select" formControlName="CenterSelected">
        <mat-option>Please select</mat-option>
        <mat-option *ngFor="let center of centers" [value]="center.CenterId">
          {{center.CenterNo}} - {{center.Name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>-->
      <br />
      <div>
        <button class="form-execute-buttons" type="button" color="primary" (click)="save()" mat-raised-button [disabled]="form.invalid">Save</button>
        <button [routerLink]="['/home']" type="button" class="form-execute-buttons" color="primary" mat-raised-button>Cancel</button>
      </div>
    </fieldset>
  </form>
  <br />
  <form *ngIf="(appService.isUserInRole('Host User') || appService.isUserInRole('Host User - Registry Only'))" [formGroup]="formAdministration">
    <fieldset>
      <legend>System administration</legend>

      <div class="control-item">
        <label for="IsOffline">System is:</label>
        <br />
        <mat-form-field class="select-form-field" floatLabel="always" appearance="fill">
          <mat-select hideSingleSelectionIndicator name="IsOffline" id="IsOffline" placeholder="Please select" formControlName="IsOffline">
            <mat-option *ngFor="let obj of enumService.enumSystemOnlineOffline" [value]="obj.value">
              {{obj.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div class="hard-error" *ngIf="formAdministration.controls['IsOffline'].hasError('required')">This field is required!</div>
      </div>

      <div class="control-item">
        <label>System message:</label>
        <input type="text" placeholder="System Message" matInput formControlName="SystemMessage">
      </div>
      <br />
      <div>
        <button class="form-execute-buttons" type="button" color="primary" (click)="updateAdministration()" mat-raised-button [disabled]="formAdministration.invalid">Save</button>
        <button [routerLink]="['/home']" type="button" class="form-execute-buttons" color="primary" mat-raised-button>Cancel</button>
      </div>
    </fieldset>
    </form>
</div>
