import { Component, Inject, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { User } from '../../classes/user';
import { DataService } from '../../services/data.service'

import { Router } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';
import { AppService } from '../../services/app.service';
import { DateTimeFormatService } from '../../services/datetime-format.service';
import { EnumService } from '../../services/enum.service';

@Component({
  selector: 'app-login-secret',
  templateUrl: './loginSecret.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginSecretComponent implements OnInit {

  currentYear;

  constructor(private dataService: DataService, private toastr: ToastrService, private router: Router, public appService: AppService
    , private dateTimeFormatService: DateTimeFormatService, private enumService: EnumService
  ) {
    this.currentYear = new Date().getFullYear();
  }

  systemVersion: any;
  showLoginButton = false;

  ngOnInit(): void {
    this.appService.TestXMLLogin = false;
    this.showLoginButton = true;

    this.systemVersion = {
      IsOffline: false
    };

    this.resetTokens();

    localStorage.removeItem('auth0token');

    
  }

  loginUserModel = new User('', '', 0, '', false);
  showTfaOptions = false;
  tfaCodeSent = false;
  showResetPassword = false;
  resetPasswordLink = '';
  fullName = '';

  testXML = false;

 
  public login() {
    // console.log(this.loginUserModel);

    this.dataService.loginSecret(this.loginUserModel).subscribe(
      (res: any) => {
        // console.log(res);

        if (res.resetLink) {
          this.showResetPassword = true;
          this.resetPasswordLink = res.resetLink;
          this.fullName = res.fullName;

          return;
        }

        //before token set, should check if some TFA message or error message is returned
        localStorage.setItem('token', res.data);
        this.toastr.success('Welcome to ELSO Registry', 'Authentication success.');

        this.dataService.get('Users/GetUserProfile', null, null).subscribe(
          (res: any) => {
            // console.log(res);
            //need to set datetime format for user here
            localStorage.setItem('unitSystem', res.UnitSystem);
            localStorage.setItem('cultureSettings', res.DateTimeFormat);
            localStorage.setItem('cultureOptionSelected', res.CultureOptionSelected);
            localStorage.setItem('centerName', res.Name);
            localStorage.setItem('centerId', res.CenterId);
            localStorage.setItem('userName', res.UserName);

            this.appService.setUserProfile();
            /////
            this.setDateTimeFormatService(res.DateTimeFormat);

            this.dataService.setUserCenter();

            if (res.Centers) {
              this.appService.userCenters = res.Centers;
            }
            this.appService.centerQuery = this.appService.UserProfile.CenterName; //this.appService.lodashFindBy(this.appService.userCenters, 'CenterId', this.appService.UserProfile.CenterId).CenterId;
            this.appService.filteredCenters = this.appService.userCenters;

            setTimeout(() => {///allow timeout for local storage to be set properly because of date format pipe display
              this.router.navigateByUrl('/home');
            }, 100);
     
          },
          err => {
            console.log(err);
          }
        );
        //this.router.navigateByUrl('/home')
      },
      err => {
        console.log(err);
        if (err.status == 400)
          this.toastr.error('Authentication failed.', err.error.message);
        else
          this.toastr.error('Unexpected error.');
      }
    )
    //return this.http.post()
  }

  setDateTimeFormatService(settings: any) {
    var cultureSettings = settings.split('|');
    var l = cultureSettings[0];
    var f = cultureSettings[1];
    var t = (cultureSettings[2] === 'true');
    this.dateTimeFormatService.locale = l;
    this.dateTimeFormatService.format = f;
    this.dateTimeFormatService.twelveHourFormat = t;
  }

  resetTokens() {
    this.dataService.resetTokens();
  }

}
