import { Component, Inject, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { User } from '../../classes/user';
import { DataService } from '../../services/data.service'

import { Router } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';
import { AppService } from '../../services/app.service';
import { DateTimeFormatService } from '../../services/datetime-format.service';
import { EnumService } from '../../services/enum.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  currentYear;

  constructor(private dataService: DataService, private toastr: ToastrService, private router: Router, public appService: AppService
    , private dateTimeFormatService: DateTimeFormatService, private enumService: EnumService
  ) {
    this.currentYear = new Date().getFullYear();
  }

  systemVersion: any;
  showLoginButton = false;
  ngOnInit(): void {
    this.appService.TestXMLLogin = false;
    this.showLoginButton = false;

    this.systemVersion = {
      IsOffline: false
    };

    this.resetTokens();
    console.log(location);
    this.dataService.getall('Settings/GetSystemVersion').subscribe(
      (res: any) => {
        //console.log(res);
        this.systemVersion = res.data;
        if (!this.systemVersion.IsOffline) {

          this.dataService.get('Users/CheckForELSOLogin', null, null).subscribe(
            (res: any) => {
              if (res.tokenValid == true) {
                localStorage.setItem('token', res.data);
                //should somehow login user with existing token
                this.dataService.get('Users/GetUserProfile', null, null).subscribe(
                  (res: any) => {
                    //need to set datetime format for user here
                    localStorage.setItem('unitSystem', res.UnitSystem);
                    localStorage.setItem('cultureSettings', res.DateTimeFormat);
                    localStorage.setItem('cultureOptionSelected', res.CultureOptionSelected);
                    localStorage.setItem('centerName', res.Name);
                    localStorage.setItem('centerId', res.CenterId);
                    localStorage.setItem('userName', res.UserName);
                    localStorage.setItem('datePickerType', res.DatePickerType);
                    localStorage.setItem('goLiveAfterDate', res.Center.goLiveAfterDate);

                    this.appService.Center = res.Center;
                    if (this.appService.Center.GoLiveAfterDate) {
                      this.appService.CenterIsXML = true;
                    }

                    this.appService.setUserProfile();
                    /////
                    this.setDateTimeFormatService(res.DateTimeFormat);

                    this.dataService.setUserCenter();


                    if (res.Centers) {
                      this.appService.userCenters = res.Centers;
                    }
                    this.appService.centerQuery = this.appService.UserProfile.CenterName; //this.appService.lodashFindBy(this.appService.userCenters, 'CenterId', this.appService.UserProfile.CenterId).CenterId;
                    this.appService.filteredCenters = this.appService.userCenters;

                    setTimeout(() => {///allow timeout for local storage to be set properly because of date format pipe display
                      this.router.navigateByUrl('/home');
                    }, 100);
                  },
                  err => {
                    console.log(err);
                  }
                );
              } else {
                this.showLoginButton = true;
                console.log('token is not valid');
              }
            },
            err => {
              console.log(err);
            }
          );
        }
      },
      err => {
        console.log(err);
      }
    );

  }

  loginUserModel = new User('', '', 0, '', false);
  showTfaOptions = false;
  tfaCodeSent = false;
  showResetPassword = false;
  resetPasswordLink = '';
  fullName = '';

  testXML = false;

  redirectToAuthentication() {
    //console.log(location.hostname);
    //if (location.hostname == 'localhost') {
    //  window.location.href = 'https://localhost:44465';
    //} else {
    window.location.href = 'https://authentication.elso.org/logoutinitialauth0';
    //window.location.href = 'https://authenticationbeta.elso.org/logoutinitialauth0?redirectLink=login';

      //window.location.href = 'https://auth.elso.org';      
    //}
  }
  redirectToAuthenticationLogout() {
      window.location.href = 'https://authentication.elso.org/logout';
  }
  
  setDateTimeFormatService(settings: any) {
    var cultureSettings = settings.split('|');
    var l = cultureSettings[0];
    var f = cultureSettings[1];
    var t = (cultureSettings[2] === 'true');
    this.dateTimeFormatService.locale = l;
    this.dateTimeFormatService.format = f;
    this.dateTimeFormatService.twelveHourFormat = t;
  }

  resetTokens() {
    this.dataService.resetTokens();
  }

}
