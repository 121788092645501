//lodash
import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, ValidatorFn } from '@angular/forms';
import * as _ from 'lodash';
//import * as moment from 'moment';
import moment from 'moment';

import { BehaviorSubject } from 'rxjs';
import { cloneDeep } from 'lodash';
import { UUID } from 'angular2-uuid';
//import { BooleanFn } from '../components/run/addenda/covid/covidAddendaEdit.component';

export interface BooleanFn {
  (): boolean;
}
Injectable()
export class AppService {
  conditionalValidator(
    predicate: BooleanFn,
    validator: ValidatorFn
  ): ValidatorFn {
    return formControl => {
      //console.log(formControl);
      //console.log(predicate);
      if (!formControl.parent) {
        return null;
      }
      let error = null;
      if (predicate()) {
        error = validator(formControl);
      }
      return error;
    };
  }
  constructor() {
    if (this.UserProfile == undefined) {
      this.setUserProfile();
    }
  }
  public isLoading = new BehaviorSubject(false);
  public registryVersion = '5.5.91';
  
  InRunEditMode: string = '';

  Run: any;
  Patient: any;
  AllDateValidations: any[];
  MinMaxHardSoftValues: [];
  UserProfile: any;
  Center: any;
  RunEnumsList: any;
  allWizardOptions: [];

  membraneLungsCenterSpecific: any;
  bloodPumpsCenterSpecific: any;
  hemofiltersCenterSpecific: any;
  temperatureRegulationsCenterSpecific: any;
  cannulasCenterSpecific: any;

  //checkMode(routeLink: string): string {
  //  if (this.InEditMode) {
  //    routeLink = routeLink + '/edit';
  //  }
  //  return routeLink + '/' + this.Run.RunId;
  //}
  CenterIsXML: boolean = false;
  TestXMLLogin: boolean = false;
  RunCanBeEdited: boolean = false;

  setUserProfile(): void {
    /*console.log(localStorage.getItem('datePickerType'));*/
    this.UserProfile = {
      UnitSystem: localStorage.getItem('unitSystem'),
      CultureOptionSelected: localStorage.getItem('cultureOptionSelected'),
      DateTimeFormat: localStorage.getItem('cultureSettings'),
      UserRoles: this.getUserRoles(),
      CanEditRunPatient: this.roleMatch(this.editPatientRunRoles),
      CenterName: localStorage.getItem('centerName'),
      CenterId: localStorage.getItem('centerId'),
      UserName: localStorage.getItem('userName'),
      DatePickerType:
        (localStorage.getItem('datePickerType') != null &&
          localStorage.getItem('datePickerType') != undefined &&
          localStorage.getItem('datePickerType') != 'null') ? Number(localStorage.getItem('datePickerType')) : 1
      //GoLiveAfterDate: localStorage.getItem('goLiveAfterDate')
    }

    if (this.UserProfile.DatePickerType == null || this.UserProfile.DatePickerType == undefined) {
      this.UserProfile.DatePickerType = 1;
    }
    console.log(this.UserProfile);
    //if (this.UserProfile.GoLiveAfterDate != null) {
    //  this.UserProfile.CanEditRunPatient = false;
    //}
  }

  checkRunEditStatus() {
    if (this.UserProfile.CanEditRunPatient && ((!this.CenterIsXML && this.Run.Source != 7) || (this.Run.TestRun))) {
      this.RunCanBeEdited = true;
    } else {
      this.RunCanBeEdited = false;
    }
  };

  setAppRun(UpdatedRun: any): void {
    this.Run = cloneDeep(UpdatedRun);
  }

  setAppRunAndValidation(RunWithValidation: any): void {
    this.Run = cloneDeep(RunWithValidation.Run);
    this.AllDateValidations = RunWithValidation.DateValidationList;
    this.MinMaxHardSoftValues = RunWithValidation.MinMaxHardSoftValidationList;
  }

  //Codes/options from database
  PreECLSSupportCodes: any[];
  userCenters = [];
  public form!: FormGroup;
  fb: FormBuilder;

  setRunForm(run: any): FormGroup {
    this.fb = new FormBuilder;
    this.form = this.fb.group({});
    this.form = this.fb.group(run);
    //console.log(run);
    this.form.controls['Supports'] = this.fb.array(run.Supports);
    this.form.controls['BloodGases'] = this.fb.array(run.BloodGases);
    this.form.controls['VentSettings'] = this.fb.array(run.VentSettings);
    this.form.controls['Hemodynamics'] = this.fb.array(run.Hemodynamics);
    this.form.controls['Equipment'] = this.fb.array(run.EquipmentHistories);
    this.form.controls['EquipmentHistories'] = this.fb.array(run.EquipmentHistories);
    this.form.controls['RunDetails'] = this.fb.array(run.RunDetails);
    this.form.controls['Cannulations'] = this.fb.array(run.Cannulations);
    this.form.controls['Complications'] = this.fb.array(run.Complications);
    this.form.controls['Infections'] = this.fb.array(run.Infections);
    this.form.controls['Diagnosis1s'] = this.fb.array(run.Diagnosis1s);
    this.form.controls['ICD10WizardSavedCodes'] = this.fb.array(run.ICD10WizardSavedCodes);
    this.form.controls['CPTProcedures'] = this.fb.array(run.CPTProcedures);
    this.form.controls['SeverityScores'] = this.fb.array(run.SeverityScores);
    this.form.controls['CovidAddenda'] = this.fb.array(run.CovidAddenda);
    this.form.controls['ECPR2020Addenda'] = this.fb.array(run.ECPR2020Addenda);
    this.form.controls['ECPRAddenda'] = this.fb.array(run.ECPRAddenda);
    this.form.controls['CardiacAddenda'] = this.fb.array(run.CardiacAddenda);
    this.form.controls['Cardiac2022Addenda'] = this.fb.array(run.Cardiac2022Addenda);
    this.form.controls['TraumaAddenda'] = this.fb.array(run.TraumaAddenda);

    this.form.controls['AscendAddenda'] = this.fb.array(run.AscendAddenda);
    //console.log(this.form.value);
    return this.form;
  }

  setECPRAddendumForm(ecprAddendum: any): FormGroup {
    this.fb = new FormBuilder;
    this.form = this.fb.group({});
    this.form = this.fb.group(ecprAddendum);
    this.form.controls['ECPR2020AntecedentEvents'] = this.fb.array(ecprAddendum.ECPR2020AntecedentEvents);
 
    return this.form;
  }

  centerQuery = null;
  filteredCenters = [];

 
  IsUs() {
    var isUs = false;
    if (this.UserProfile.UnitSystem == 1) { // US
      isUs = true;
    }
    else if (this.UserProfile.UnitSystem == 0) { // SI
      isUs = false;
    }
    else if (this.UserProfile.UnitSystem == 2) { // US
      isUs = true;
    }

    return isUs
  };
  ExcludedConditions: any[];
  EducationLevels: any[];
  HCInsuranceStatuses: any[];
  enumPMHx: any[];
  /////////////////////
  //moment
  toMomentDate(val): any {
    if (val == null) {
      return null;
    }
    return moment(val).utc(true);
  }
  toMomentCurrentDate(): any {
    return moment().utc(true);
  }
  //lodash
  lodashRemoveBy(array, propertyName, propertyValue) : any {
    return _.remove(array, [propertyName, propertyValue]);
  };
  lodashWithout(array, value): any {
    return _.without(array, value);
  };
  lodashFindBy(array, propertyName, propertyValue) {
    return _.find(array, [propertyName, propertyValue]);
  };
  lodashFindByMultiple(array, propertyNames, propertyValues) {
    for (var i = 0; i <= propertyNames.length - 1; i++) {
      array = _.filter(array, [propertyNames[i], propertyValues[i]]);
    }
    return array[0];
  };
  lodashFilterBy(array, propertyName, propertyValue) {
    return _.filter(array, [propertyName, propertyValue]);
  };
  lodashFilterByMultiple(array, propertyNames, propertyValues) {
    for (var i = 0; i <= propertyNames.length - 1; i++) {
      array = _.filter(array, [propertyNames[i], propertyValues[i]]);
    }
    return array;
  };
  lodashSortBy(array, propertyName) {
    return _.sortBy(array, [propertyName]);
  };
  lodashMapBy(array, propertyName) {
    return _.map(array, propertyName);
  };
  lodashUniqeInitialDevices(array) {
    return _.uniq(_.map(array, 'InitialDeviceId'));
  };
  lodashUniq(array) {
    return _.uniq(array);
  };
  lodashUniqByProperty(array, property) {
    return _.uniq(_.map(array, property));
  };
  lodashDeepClone(obj) {
    return _.cloneDeep(obj);
  };
  lodashOrderBy(array, propertyArray, ascDescArray): any {
    return _.orderBy(array, propertyArray, ascDescArray);
  };
  lodashMaxNumberValue(array): number {
    return _.max(array);
  };
  getArrayOfNumbersBetween(start, end) {
    var list = [];
    for (var i = start; i <= end; i++) {
      list.push(i);
    }
    return list;
  };
  //measure units/conversion
  getMeasureUnit(conversionType): string {

    //get unit here from global variable or local storage
    var isUS = false;
    if (this.UserProfile.UnitSystem == 1) {
      isUS = true;
    }

    var ret = '';
    var spl = conversionType.split('-'); //[0] SI, [1] US
    if (isUS) {
      ret = spl[1];
    } else {
      ret = spl[0];
    }
    return ret;
  }

  getObjectToCompare(objectName: string): any {
    switch (objectName) {
      case 'p':
        return this.Patient;
      case 'r':
        return this.Run;        
    }

    return undefined;
  }


  getDateErrorMessage(objectName: string, comparisonSign: string): string {
    var comparisonWord = '';
    if (comparisonSign == '>') {
      comparisonWord = 'after';
    } else if (comparisonSign == '<') {
      comparisonWord = 'before';
    }

    switch (objectName) {
      case 'Birthdate':
        return 'This date cant be before patient Birthdate';
      case 'TimeOn':
        return 'This date cant be ' + comparisonWord + ' run TimeOn';
      case 'DeathDate':
        return 'This date cant be ' + comparisonWord + ' DeathDate';
      case 'TimeOff':
        return 'This date cant be after run TimeOff';
    }
  }

  editPatientRunRoles = ['ECLS Center Administrator', 'ECLS Center Data Entry', 'ECLS Center Data Manager', 'Host User', 'Host User - Registry Only']

  roleMatch(allowedRoles): boolean {
    var isMatch = false;
    if (localStorage.getItem('token') != undefined) {
      var payLoad = JSON.parse(window.atob(localStorage.getItem('token').split('.')[1]));
      var userRoles = payLoad.role;
      //console.log(payLoad);
      //console.log(localStorage.getItem('token'));

      if (userRoles != undefined) {
        for (var i = 0; i <= userRoles.length - 1; i++) {
          if (allowedRoles.indexOf(userRoles[i]) > -1) {
            isMatch = true;
          }
        }
      }
      
    }
    

    //allowedRoles.forEach(element => {
    //  if (userRole == element) {
    //    isMatch = true;
    //    return false;
    //  }
    //})
    return isMatch;
  }

  isUserInRole(roleName): boolean {
    if (this.UserProfile) {
      //console.log(this.UserProfile);
      var userRoles = this.UserProfile.UserRoles;
      if (userRoles.indexOf(roleName) > -1) {
        return true;
      }
    }
    
    return false;
  }

  getUserRoles() {
    if (localStorage.getItem('token') != undefined) {
      var payLoad = JSON.parse(window.atob(localStorage.getItem('token').split('.')[1]));
      return payLoad.role
    } else {
      return [];
    }
  }

  getEnumName(objValue: any, enumList: any[], valueProperty?: string, namePropertyString?: string) {
    if (objValue != null) {
      if (!valueProperty && !namePropertyString) {
        //console.log(enumList);
        return this.lodashFindBy(enumList, 'value', objValue).name;
      } else {
        return this.lodashFindBy(enumList, valueProperty, objValue)[namePropertyString];
      }
    }
  }

  generateUUID() {
    return UUID.UUID();
  }

  getFormatedStringFromDays(numberOfDays) {
    if (numberOfDays == 0) {
      return "0 days";
    }

    var years = Math.floor(numberOfDays / 365);
    var months = Math.floor(numberOfDays % 365 / 30);
    var days = Math.floor(numberOfDays % 365 % 30);

    var yearsDisplay = years > 0 ? years + (years == 1 ? " year" : " years") : "";
    var monthsDisplay = months > 0 ? months + (months == 1 ? " month" : " months") : "";
    var daysDisplay = days > 0 ? days + (days == 1 ? " day" : " days") : "";

    if (years >= 1) {
      return yearsDisplay;
    } else if (months >= 1) {
      return monthsDisplay;
    } else if (days >= 1) {
      return daysDisplay;
    }
    //return yearsDisplay + monthsDisplay + daysDisplay;
  }

  getHostLocation = function (href) {
    var l = document.createElement("a");
    l.href = href;
    return l.hostname;
  };
}
