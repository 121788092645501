<h1 mat-dialog-title>Scanned devices</h1>
<form autocomplete="off" [formGroup]="form">
<div mat-dialog-content>
  <div>
    <ng-template #dynamicComponent></ng-template>
    <div class="control-item">
      <label class="required-field">Device type:</label>
      <br />
      <mat-form-field class="select-form-field" floatLabel="always" appearance="fill">
        <mat-select hideSingleSelectionIndicator name="DeviceType" id="DeviceType" placeholder="Please select" (selectionChange)="deviceTypeSelectionChange()" formControlName="DeviceType">
          <mat-option>Please select</mat-option>
          <mat-option *ngFor="let dt of enumService.enumScanDeviceTypes" [value]="dt.value">
            {{dt.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div class="hard-error" *ngIf="form.controls['DeviceType'].hasError('required')">This field is required!</div>
    </div>
    <div class="control-item" *ngIf="scannedDeviceGroups.length > 0 && scanOptionSelected != 3">
      <label class="required-field">Pre scanned devices:</label>
      <br />
      <mat-form-field class="select-form-field" floatLabel="always" appearance="fill">
        <mat-select hideSingleSelectionIndicator name="PreScannedDevice" id="PreScannedDevice" placeholder="Please select" formControlName="PreScannedDevice">
          <mat-option>Please select</mat-option>
          <mat-option *ngFor="let o of scannedDeviceGroups" [value]="o.DeviceGudidId">
            {{o.DeviceGudidId}} - {{o.DeviceScanSetName}} - ({{appService.getEnumName(o.DeviceType, enumService.enumScanDeviceTypes)}})
          </mat-option>
        </mat-select>
      </mat-form-field>
      <button class="form-execute-buttons" type="button" color="primary" mat-raised-button (click)="addPreScannedDevice()" [disabled]="form.controls['PreScannedDevice'].value == null">Add device</button>
    </div>
    <div class="elso-grid" *ngIf="scannedDevices.length > 0">
      <div>Scanned devices:</div>
      <br />
      <div class="elso-grid-row" *ngFor="let scannedDevice of scannedDevices">
        <div style="width: 100%;">
          <a (click)="deleteScannedDevice(scannedDevice)">
            <mat-icon class="mat-icon" matTooltip="Delete" matTooltipPosition="above" color="primary">delete</mat-icon>
          </a>
        </div>
        <div style="width: 100%;">
          <span class="elso-grid-label" style="text-wrap: balance;">Device GUDID id: {{scannedDevice.DeviceGudidId}}</span>
        </div>
        <div class="control-item">
          <span class="elso-grid-label">Device type: {{appService.getEnumName(scannedDevice.DeviceType, enumService.enumScanDeviceTypes)}}</span>
        </div>
        <div class="control-item" *ngIf="scannedDevice.DeviceScanSetName != null">
          <span class="elso-grid-label">Equipment group: {{scannedDevice.DeviceScanSetName}}</span>
        </div>
      </div>
    </div>
    <br />
    <div>Patient: </div>
    <div>
      <div class="elso-grid" *ngIf="selectedPatient">
        <div class="elso-grid-row">
          <div class="control-item">
            <span class="elso-grid-label">UniqueId: {{selectedPatient.PatientUniqueId}}</span>
          </div>
          <br />
          <div class="control-item">
            <span class="elso-grid-label">Birthdate: {{selectedPatient.BirthDate | dateFormat}}</span>
          </div>
          <div class="control-item" *ngIf="selectedPatient.TimeOn">
            <span class="elso-grid-label">Time on: {{selectedPatient.TimeOn | dateFormat}}</span>
          </div>
          <div class="control-item" *ngIf="selectedPatient.TimeOff">
            <span class="elso-grid-label">Time off: {{selectedPatient.TimeOff | dateFormat}}</span>
          </div>
          <br />
          <div class="control-item">
            <span class="elso-grid-label">Sex: {{appService.getEnumName(selectedPatient.Sex, appService.enumSex)}}</span>
          </div>
        </div>
      </div>
    </div>
    <div mat-dialog-actions>
      <button class="form-execute-buttons" type="button" color="primary" mat-raised-button (click)="confirmDialogOption('yes')" [disabled]="form.invalid || scannedDevices.length == 0">Save</button>
      <button class="form-execute-buttons" type="button" color="primary" mat-raised-button (click)="confirmDialogOption('cancel')">Cancel</button>
    </div>


  </div>
</div>
</form>

